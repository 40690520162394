import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DeleteConfirmationPopup from "./DeleteConfirmationPopup";

function Shop() {

  useEffect(() => {
    getshop();
  }, []);

  const [show, setShow] = useState(false);
  const [shop, setshop] = useState([]);
  const [selectedshop, setselectedshop] = useState(null);
  const [inputs, setInputs] = useState({});
  const handleShow = () => setShow(true);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ShopToDeleteId, setShopToDeleteId] = useState(null);
  
  const handleClose = () => {
    setShow(false);
    setselectedshop(null);
  };

  const getshop = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/Shop/`
      );
      setshop(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteshop = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE}/Shop/${id}/`);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const editshop = async (shopdata) => {
    setselectedshop(shopdata);
    handleShow();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let response;
      if (selectedshop) {
        let updatedShop = { ...selectedshop };

        Object.keys(inputs).forEach((key) => {
          if (inputs[key] !== selectedshop[key]) {
            updatedShop[key] = inputs[key];
          }
        });

        response = await axios.put(
          `${process.env.REACT_APP_API_BASE}/Shop/${selectedshop.id}`,
          updatedShop
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE}/Shop/`,
          inputs
        );
        console.log(response);
      }
      setShow(false);
      getshop();
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };


  return (
    <div className="container">
      <ToastContainer position="bottom-right"></ToastContainer>
      <DeleteConfirmationPopup
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        onConfirm={async () => {
          await deleteshop(ShopToDeleteId);
          setShowDeleteConfirmation(false);
        }}
      />
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
        <div className="row">
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <div className="search"></div>
          </div>
          <div
            className="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred"
            style={{ color: "green" }}
          >
            <h4>
              <b>Shop SEO Tags</b>
            </h4>
          </div>
          <div className="col-sm-3 offset-sm-1  mt-5 mb-4 text-gred">
            <Button variant="primary" onClick={handleShow}>
              Add New Shop Page SEO Tags
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tittle</th>
                  <th>Description</th>
                  <th>Keywords</th>
                  <th>Edit</th>
                  <th>Created At</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {shop.map((shopItem, key) => (
                  <tr key={key}>
                    <td>{shopItem.id}</td>
                    <td>{shopItem.tittle}</td>
                    <td>{shopItem.description}</td>
                    <td>{shopItem.keywords}</td>
                    <td>
                      <Button
                        onClick={() => editshop(shopItem)}
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                        style={{ color: "Green", background: "white" }}
                      >
                        <i className="material-icons">&#xe065;</i>
                      </Button>
                    </td>
                    <td>{shopItem.created_at}</td>
                    <td>
                      <Button
                        onClick={() => {
                          setShopToDeleteId(shopItem.id);
                          setShowDeleteConfirmation(true);
                        }}
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                        style={{ color: "red", background: "white" }}
                      >
                        <i className="material-icons">&#xE872;</i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="model_box">
          <Modal
            show={show}
            data={selectedshop}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedshop ? "Edit Shop" : "Add New Shop"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
              <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Shop Page Tittle"
                    defaultValue={selectedshop ? selectedshop.tittle : ""}
                    name="tittle"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Shop Page Description"
                    defaultValue={selectedshop ? selectedshop.description : ""}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Shop Page Key Words"
                    defaultValue={
                      selectedshop ? selectedshop.keywords : ""
                    }
                    name="keywords"
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn btn-success mt-4">
                  {selectedshop ? "Edit Shop Page Tags" : "Add Shop Page Tags"}
                </button>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Shop;
