import * as React from "react";
import { Admin, Resource, Login, defaultTheme } from 'react-admin';
import authProvider from "./components/Authentication/authProvider";
import Home from "./components/Home";
import Shop from "./components/Shop";
import Printing from "./components/Printing";
import ContactUs from "./components/ContactUs";
import Orders from "./components/Orders";
import Products from "./components/Products";
// import BlogsSeo from "./components/BlogsSeo";
// import Blogs from "./components/Blogs";

// Define themes
const lightTheme = defaultTheme;
const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

// Clear session storage
sessionStorage.clear();

const App = () => (
  <Admin
    theme={lightTheme}
    darkTheme={darkTheme}
    authProvider={authProvider}
    requireAuth
  >
  <Resource name="Order" list={Orders} login={Login} options={{ label: "Orders" }} />
  <Resource name="Product" list={Products} login={Login} options={{ label: "Products" }} />
  <Resource name="Home" list={Home} login={Login} options={{ label: "Home" }} />
  <Resource name="Shop" list={Shop} login={Login} options={{ label: "Shop" }} />
  <Resource name="Printing" list={Printing} login={Login} options={{ label: "Printing" }} />
  <Resource name="Contact_Us" list={ContactUs} login={Login} options={{ label: "ContactUs" }} />
  {/*   
    <Resource name="Blogs_SEO" list={BlogsSeo} login={Login} options={{ label: "Blogs SEO" }} />
    <Resource name="Blogs" list={Blogs} login={Login} options={{ label: "Blogs" }} />
  */}
  </Admin>
);
export default App;
