import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DeleteConfirmationPopup from "./DeleteConfirmationPopup";

function Orders() {

  useEffect(() => {
    getorders();
  }, []);

  const [orders, setorders] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [OrdersToDeleteId, setOrdersToDeleteId] = useState(null);
  

  const getorders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/Orders/`
      );
      setorders(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteorders = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE}/Orders/${id}/`);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };



  return (
    <div className="container">
      <ToastContainer position="bottom-right"></ToastContainer>
      <DeleteConfirmationPopup
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        onConfirm={async () => {
          await deleteorders(OrdersToDeleteId);
          setShowDeleteConfirmation(false);
        }}
      />
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
        <div className="row">
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <div className="search"></div>
          </div>
          <div
            className="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred"
            style={{ color: "green" }}
          >
            <h4>
              <b>Orders</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Product Name</th>
                  <th>Customer Name</th>
                  <th>Mobile Number</th>
                  <th>Email ID</th>
                  <th>Quantity</th>
                  <th>Created At</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((ordersItem, key) => (
                  <tr key={key}>
                    <td>{ordersItem.id}</td>
                    <td>{ordersItem.product_name}</td>
                    <td>{ordersItem.customer_name}</td>
                    <td>{ordersItem.phone_number}</td>
                    <td>{ordersItem.email_id}</td>
                    <td>{ordersItem.quantity}</td>
                    <td>{ordersItem.created_at}</td>
                    <td>
                      <Button
                        onClick={() => {
                          setOrdersToDeleteId(ordersItem.id);
                          setShowDeleteConfirmation(true);
                        }}
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                        style={{ color: "red", background: "white" }}
                      >
                        <i className="material-icons">&#xE872;</i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
