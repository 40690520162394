import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ImageUploaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      file: null,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  onFileChange(e) {
    this.setState({
      name: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  }

  uploadFile() {
    const formData = new FormData();
    formData.append("image", this.state.file);
    formData.append("data", this.props.data);
    let url = 'https://seo.backend.geniearabia.com/upload.php';
    axios
      .post(url, formData, {
        // Post the form data to url
      })
      .then((res) => {
        // Upload Response
        if (res.status === 200) {
          toast.success("Image Upload Success");
        } else {
          toast.error("Image Upload Failed Try Again");
        }
      });
  }

  render() {
    return (
      <div className="row">
        <ToastContainer position="top-center" autoClose="3000"></ToastContainer>
        <div className="col-12">
          <form>
            <div
              className="form-group"
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              <input
                type="file"
                name="image"
                className="form-control"
                onChange={this.onFileChange}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={this.uploadFile}
            >
              Upload Image
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ImageUploaderComponent;